import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/serwis-praca.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import Container from "../components/container";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "zlecenie-cykliczne-dodawanie.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "tablica-kanban-przeplyw-zlecen.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "aplikacja-serwis.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(
      relativePath: { eq: "questy-kartoteki-produktow-serwisowanych.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    zdjecie5: file(relativePath: { eq: "karta-zlecenia-serwisowego.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const Zleceniacykliczne = ({ data }) => {
  return (
    <Artykul
      title="Jak wygląda system do obsługi zgłoszeń serwisowych?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Jak wygląda system do obsługi zgłoszeń serwisowych?"
      metaTitle="Jak wygląda system do obsługi zgłoszeń serwisowych?"
      metaDescription="Jak skutecznie zarządzać zleceniami cyklicznymi? ✅ Jak automatycznie wysyłać klientom przypomnienia SMS o przeglądach? •  Obejrzyj video"
    >
      <p>
        Ciągły brak czasu? Gonitwa za zleceniami? Klienci, którzy ciąglę dzwonią
        i dopytują na jakim etapie jest ich zgłoszenie? Jeśli te problemy brzmią
        dla Ciebie znajomo, to nie martw się. Nasi klienci zgłaszają się z
        podobnymi problemami, więc jeśli czujesz, że jest w tym temacie pole do
        poprawy to koniecznie przeczytaj ten artykuł.
      </p>
      <div className="text-block">
        <br />
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="Pdvju_IiM2Y"
          title="Zlecenia cykliczne: jak nimi zarządzać w serwisie?"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <strong>Możliwości systemu obsługi zgłoszeń serwisowych</strong>
      </h2>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Rejestracja zleceń
      </h3>
      <p>
        System do obsługi zgłoszeń serwisowych pozwala na dodawanie zleceń przez
        różne osoby. Mowa tu o dyspozytorze, serwisantach, kierowniku, ale także
        o kliencie, który zrobi to bezpośrednio z dedykowanego mu panelu.
        Oczywiście, to Ty zdecydujesz kto będzie mógł dodawać nowe zlecenia.
      </p>
      <p>
        Koniec z ciągłymi telefonami i mailami od klientów. W dedykowanym panelu
        klienci mogą sami zrobić te rzeczy, które zabierały czas pracownikom.
        Chodzi tu o dodawanie zleceń, sprawdzanie status zlecenia oraz
        pobieranie faktur. Będziecie mogli skupić się na własnej pracy, a
        klienci otrzymają wygodne narzędzie.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz usprawnić obsługę zgłoszeń w serwisie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej obsługiwać zgłoszenia
            </Link>
          </div>
          </div>
    </section>

      <p
        style={{
          textAlign: "left",
          paddingTop: 20,
        }}
      >
        <strong>Dodawanie zgłoszenia</strong>
      </p>
      <p>
        W oknie dodawania zgłoszenia dane klienta są podpowiadane, a w
        przypadku, kiedy jest to nowy klient jego karta zostanie automatycznie
        utworzona. W oknie, oprócz podstawowych danych klienta, masz możliwość
        wpisania opisu zlecenia, wiązania serwisowanych urządzeń, dodania
        zdjęcia oraz wpisania planowanej daty rozpoczęcia zlecenia.
        <Lightbox
          style={{
            maxWidth: 800,
            margin: "0 auto",
          }}
          images={[data.zdjecie1]}
          alts={["Dodawanie zlecenia w programie do serwisu"]}
        />
      </p>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Kanban - przejrzysty widok zleceń
      </h3>
      <p>
        <strong>
          Zlecenia mogą być wyświetlane w formie listy lub tablicy kanban
        </strong>
        . W drugim przypadku zlecenia wyświetlają się na kanbanie z podziałem na
        kolumny, które odzwierciedlają poszczególne etapy realizacji zlecenia.
        Jego wygląd możesz dopasować do procesów w Twoim serwisie. To prosty
        sposób na monitorowanie statusu zleceń.{" "}
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Tablica kanban jako narzędzie do monitorowania realizacji zleceń w serwisie",
        ]}
      />
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Wsparcie mobilnych serwisantów
      </h3>
      <p>
        Jeśli Twoi serwisanci pracują w terenie, to na pewno docenisz możliwość
        planowania zleceń na podstawie informacji wyświetlanych na mapie.{" "}
        <strong>
          Możesz sprawdzić, które zlecenia znajdują się blisko siebie lub który
          z pracowników jest najbliżej miejsca zlecenia
        </strong>
        . W ten sposób optymalnie rozdzielisz pracę. Pracownik otrzyma
        powiadomienia push w aplikacji mobilnej. Oczywiście, pracę można też
        planować tradycyjnie, bez użycia map, wybierając po prostu nowe zlecenia
        z listy i przydzielając je serwisantom.
      </p>
      <Lightbox
        style={{
          maxWidth: 400,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Aplikacja na telefon dla serwisantów mobilnych"]}
      />
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Baza wiedzy o urządzeniach
      </h3>
      <p>
        Jeżeli serwisujesz maszyny lub urządzenia istotna jest dla Ciebie
        historia tego produktu. System do obsługi zgłoszeń ma wbudowaną bazę
        serwisowanych urządzeń, czyli miejsce, w którym zbierane są informacje
        takie jak: specyfikacja maszyny, historia zleceń, zaplanowanie zlecenia
        itd. To miejsce, w którym serwisanci sprawdzą pełną historię urządzeń.
        Jest to szczególnie przydatne kiedy nowy pracownik pierwszy raz spotyka
        się z problemem, który został już kiedyś rozwiązany przez inną osobę.
        Wtedy błyskawicznie może znaleźć zlecenie i zobaczyć opis rozwiązania
        razem z załącznikami.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie4]}
        alts={["Kartotreki produktów"]}
      />

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Automatyczne protokoły serwisowe
      </h3>
      <p>
        Po wykonanym zleceniu, serwisant uzupełnia informację i zmienia fazę
        zlecenia na “zakończone”. W ten sposób dowiaduje się o tym kierownik. Ty
        lub Twój pracownik z łatwością możecie automatycznie wygenerować
        elektroniczny protokół serwisowy i przesłać go klientowi. Zostaje już
        tylko wystawienie faktury i przesłanie jej klientowi (lub samodzielne
        pobieranie jej w panelu klienta).
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie5]}
        alts={["Historia współpracy z kontrahentem"]}
      />

      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 10,
        }}
      >
        Dlaczego warto pracować z systemem do obsługi zgłoszeń?
      </h2>
      <p>
        <ul>
          <li>
            Wszystkie informacje o klientach, zleceniach i urządzeniach są
            bezpiecznie przechowywane w jednym miejscu.
          </li>
          <li>W prosty i szybki sposób przekażesz zlecenia do realizacji.</li>
          <li>
            Jesteś na bieżąco w realizacji zleceń, bez poświęcania czasu na
            wielokrotne telefony do serwisantów.
          </li>
          <li>
            Nie tracisz czasu na ręczne przyjmowanie zgłoszeń, jeżeli postawisz
            na samoobsługę klienta.{" "}
          </li>
        </ul>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz usprawnić obsługę zgłoszeń w serwisie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
             Chcę wypróbować i sprawniej obsługiwać zgłoszenia
            </Link>
          </div>
          </div>
    </section>
      <p
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <strong>Dowiedz się więcej:</strong>
        <ul>
          <li>
            <Link to="/przeglady-cykliczne/">
              {" "}
              Zlecenia cykliczne - jak nimi zarządzać?
            </Link>
          </li>
          <li>
            <Link to="/baza-urzadzen/"> Sprawnie zarządzaj bazą urządzeń </Link>
          </li>
          <li>
            <Link to="/7-wyzwan-w-serwisie/">
              {" "}
              7 problemów Twojego serwisu – jak je rozwiązać
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Zleceniacykliczne;
